import {
	Center,
	VStack,
	Text,
	Link,
} from "@chakra-ui/react";
import React from "react";
import api from "resources/API";
import { Fetchable } from "../Fetchable";
import strings from "resources/Strings";
import { observer } from "mobx-react-lite";
import { SelectItem } from "../SelectItem";

interface IProps {
	data?: api.Product[];
	loading?: boolean;
	searching?: boolean;
	onClick: () => void;
	isOpen: boolean;
}

export const AutoCompleteProducts: React.FC<IProps> = observer((props) => {

	const {
		data,
		loading,
		searching,
		onClick,
		isOpen,
	} = props;

	return (
		<Fetchable loading={loading}>
			{
				isOpen && (
					<VStack
						spacing={4}
						maxH={300}
						overflowY="auto"
					>
						{
							data && (
								data.map((item, index) =>
										<Link key={index} href={`/products/${item.slug}`}>
											<SelectItem
												product={item.title}
												key={index}
												onClick={onClick}
											/>
										</Link>,
									)
							)
						}
					</VStack>
				)
			}

			{searching && (
				<Center>
					<Text>{strings.error.emptyProductsList}</Text>
				</Center>
			)}
		</Fetchable>
	);
});
