import { action, makeAutoObservable } from "mobx";

interface DialogData {
	title: string;
	message: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	label?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
}

export default class UIStore {

	private _snackbarMessage: string | null = null;
	private _dialogData: DialogData | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	public get snackbarMessage() {
		return this._snackbarMessage;
	}

	public showSnackbar = (message: string) => {
		this._snackbarMessage = message;
		setTimeout(action(() => this._snackbarMessage = null), 4000);
	};

	public get dialogData() { return this._dialogData; }
	public showDialog = (data: DialogData) => this._dialogData = data;
	public clearDialog = () => this._dialogData = null;
}
