import { Flex, Text, Image, Box, Divider, propNames } from "@chakra-ui/react";
import { Link } from "../../../../Link";

import React from "react";

interface IProps {
	label: string;
	src?: string;
	isHidden?: boolean;
	route: {
		href: string;
	};
	nextLinkProps?: { href: { pathname: string; query: { redirectOnLogin: string; }}};
}

export const DrawerItem: React.FC<IProps> = ({
	label,
	route,
	src,
	isHidden,
	nextLinkProps,
}) => {
	return (
		<>
			{!isHidden && (
				<>
					<Box width="100%" paddingX={1} paddingY={1}>
						<Link href={route.href} nextLinkProps={nextLinkProps}>
							<Flex justify="space-between" align="center">
								<Text fontSize="lg">{label}</Text>
								{src && (
									<Image width="20px" height="20px" src={src} />
								)}
							</Flex>
							<Divider mt={1} borderBottomColor="#4A5568" />
						</Link>
					</Box>
				</>
			)}
		</>
	);
};
