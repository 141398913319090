import React, { useCallback, useRef, useMemo } from "react";
import { Flex, Image, HStack, Text, Button, VStack } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { useRouter } from "next/router";
import Drawer from "./components/Drawer";
import { userItems, sellerItems, notLoggedTopItems } from "./tabItems";
import { SearchInput } from "../SearchInput";
import { IExportProps } from "./components/Drawer";
import { useUserAuth } from "contexts/UserAuthContext";
import { BottomTabBar } from "./components/BottomTabBar";
import { IconWithNotification } from "../IconNotification";
import { Link } from "../Link";
import Strings from "resources/Strings";
import * as api from "@startapp/laira-user-ssr-api";

interface IProps {
	hasSearchBar?: boolean;
	isHiddenSearchBarMobile?: boolean;
	isHiddenMenu?: boolean;
	isHiddenBottomBar?: boolean;
	isEmpty?: boolean;
}

export const AppBar: React.FC<IProps> = (props) => {
	const authStore = useUserAuth();
	const router = useRouter();
	const drawerRef = useRef<IExportProps>(null);
	const openDrawer = () => drawerRef.current.onOpen();

	const topTapItems = useMemo(
		() =>
			authStore.user?.store
				? sellerItems(authStore.user)
				: authStore.user
					? userItems(authStore.user)
					: notLoggedTopItems(),
		[authStore.user],
	);

	const submitInput = useCallback((currentValue: string) => {
		const search = currentValue.trim();
		const hasText = search.length > 0;
		const query = {
			page: 0,
			category: "all",
		};
		router.push({
			pathname: "/search/[category]",
			query: hasText ? { ...query, search } : query,
		});
	}, []);

	const { welcomeTexts } = Strings.components.navbar;
	const _cat = router.query.category as string;
	const category = _cat === "all" ? null : (_cat as api.Category);

	return (
		<React.Fragment>
			<Flex
				paddingX={5}
				paddingY={2}
				position="fixed"
				zIndex={999}
				direction={{ base: "column", lg: "row" }}
				justifyContent={{ base: "flex-start", lg: "space-between" }}
				backgroundColor="white"
				width="100%"
				borderBottom="2px solid"
				borderBottomColor="gray.100"
			>
				<Flex
					position="relative"
					alignItems="center"
					justifyContent="left"
					height="40px"
				>
					<Link href="/">
						<Image height="24px" src="/logo.svg" />
					</Link>
					{!props.isHiddenMenu && (
						<Box
							right={0}
							display={{ sm: "block", lg: "none" }}
							position="absolute"
						>
							<Button
								marginTop="5px"
								marginBottom="5px"
								onClick={openDrawer}
								borderRadius="5px"
								fontWeight="500"
								color="#838383"
							>
								Menu
							</Button>
						</Box>
					)}
				</Flex>
				<Flex
					justifyContent="center"
					alignItems="center"
					display={{ lg: "flex", md: "none", base: "none" }}
				>
					{props.hasSearchBar && (
						<SearchInput
							onSubmit={submitInput}
							category={category}
						/>
					)}
				</Flex>
				<HStack spacing={10} paddingLeft={2} display={{ base: "none", lg: "flex" }}>
					{!props.isEmpty &&
						topTapItems.map((item, index) => (
							<IconWithNotification
								{...item}
								onlyImage
								key={`${item}#${index}`}
							/>
						))
					}
					<VStack alignItems="flex-start" spacing={0}>
						{!props.isEmpty && (
							authStore?.user ? (
								<>
									<Text fontWeight={"500"}>
										{welcomeTexts.welcomeWithUser.text(
											authStore.user.name.split(" ")[0],
										)}
									</Text>
									<Link fontSize="sm" href="/profile">
										{welcomeTexts.welcomeWithUser.link}
									</Link>
								</>
							) : (
								<>
									<Text fontWeight={"500"}>
										{welcomeTexts.welcomeWithoutUser.text}
									</Text>
									<Link fontSize="sm" href="/login">
										{welcomeTexts.welcomeWithoutUser.link}
									</Link>
								</>
							))}
					</VStack>
					<Button
						onClick={openDrawer}
						fontWeight="500"
						color="#838383"
						borderRadius="5px"
					>
						Menu
					</Button>
				</HStack>
				<Drawer ref={drawerRef} />
			</Flex>
			<div style={{ width: "100%", height: "62px" }} />
			{!props.isHiddenBottomBar && <BottomTabBar />}

			{props.hasSearchBar && (
				<Box
					display={{ lg: "none", base: props.isHiddenSearchBarMobile ? "none" : "block" }}
					paddingX={4}
					marginTop={2}
				>
					<SearchInput onSubmit={submitInput} />
				</Box>
			)}
		</React.Fragment>
	);
};
