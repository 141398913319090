import React, { forwardRef, useImperativeHandle } from "react";
import { useRouter } from "next/router";
import {
	DrawerOverlay,
	Drawer as ChakraDrawer,
	useDisclosure,
	DrawerContent,
	DrawerBody,
	DrawerHeader,
	DrawerCloseButton,
	Flex,
	Text,
	Divider,
	Button,
} from "@chakra-ui/react";

import Strings from "resources/Strings";
import Routes from "resources/Routes";
import { useUserAuth } from "contexts/UserAuthContext";
import { useUIStore } from "contexts/UIStoreContext";
import { DrawerItem } from "./DrawerItem";

export interface IExportProps {
	onOpen: () => void;
	onClose: () => void;
	isOpen: boolean;
}

interface IProps { }
type DrawerComponent = React.ForwardRefRenderFunction<IExportProps, IProps>;

const Drawer: DrawerComponent = (props, ref) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { user, logout } = useUserAuth();
	const uiStore = useUIStore();
	const router = useRouter();

	useImperativeHandle(ref, () => ({ isOpen, onClose, onOpen }), [
		isOpen,
		onOpen,
		onClose,
	]);

	return (
		<ChakraDrawer isOpen={isOpen} placement="right" onClose={onClose}>
			<DrawerOverlay>
				<DrawerContent>
					<DrawerHeader display="flex" justifyContent="space-between">
						<Flex
							justifyContent="space-between"
							alignItems="center"
							width="100%"
						>
							<Text>{Strings.general.welcomeUser(user)}</Text>
							<DrawerCloseButton position="unset" />
						</Flex>
					</DrawerHeader>
					<Divider variant="solid" background="#" />
					<DrawerBody>
						<DrawerItem
							label={user ? Strings.general.profile : Strings.general.enterNow}
							route={Routes.profile()}
						/>
						{ !user &&
							<DrawerItem
								label={Strings.general.registration}
								route={Routes.register()}
							/>
						}
						<DrawerItem
							label={Strings.project.cart}
							route={Routes.shoppingCart()}
						/>
						<DrawerItem
							label={Strings.general.favorites}
							route={Routes.favorites()}
						/>
						<DrawerItem
							label={Strings.project.chatRooms}
							route={Routes.chats()}
						/>

						<DrawerItem
							label={
								user?.store
									? Strings.project.purchases
									: Strings.general.orderHistory
							}
							route={Routes.orderHistory()}
						/>
						{user?.store && (
							<React.Fragment>
								<DrawerItem
									label={Strings.general.mySales}
									route={Routes.orderHistoryStore()}
								/>
								<DrawerItem
									label={Strings.general.myEarnings}
									route={Routes.myEarnings()}
								/>
							</React.Fragment>
						)}
						<DrawerItem
							label={Strings.general.help}
							route={Routes.help()}
						/>
						<Divider />
						{user && (
							<Button
								marginTop={3}
								fontSize="lg"
								variant="link"
								onClick={() =>
									uiStore.showDialog({
										title: Strings.general.exit,
										message: Strings.general.logoutMessage,
										onConfirm: () =>
											logout(() => router.push("/login")),
									})
								}
							>
								{Strings.general.exit}
							</Button>
						)}
					</DrawerBody>
				</DrawerContent>
			</DrawerOverlay>
		</ChakraDrawer>
	);
};

export default forwardRef(Drawer);
