import React from "react";

import {
	Link as ChakraLink,
	LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";

interface IProps extends ChakraLinkProps {
	nextLinkProps?: NextLinkProps;
}

export const Link: React.FC<IProps> = (props) => {
	const { children, href, nextLinkProps, ...rest } = props;

	return (
		<NextLink passHref href={href} {...nextLinkProps}>
			<ChakraLink {...rest}>{children}</ChakraLink>
		</NextLink>
	);
};
