import { Flex, Tooltip, VStack, Image, Text } from "@chakra-ui/react";
import { Link } from "../../../components/general/Link";
import React from "react";

export interface Props {
	label: string;
	src: string;
	route: { href: string };
	haveNotification?: number | string;
	onlyImage?: boolean;
	isHidden?: boolean;
}

export const IconWithNotification: React.FC<Props> = ({
	haveNotification,
	label,
	route,
	src,
	onlyImage,
	isHidden,
}) => {
	return (
		<>
			{!isHidden && (
				<Link key={src} href={route.href} textAlign="center">
					<Tooltip label={label}>
						<VStack>
							<Flex
								position="relative"
								justify="center"
								align="center"
								_after={
									haveNotification
										? {
											fontWeight: "md",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "16px",
											height: "16px",
											fontSize: "9px",
											position: "absolute",
											content: `"${haveNotification}"`,
											borderRadius: "50%",
											bg: "#576D6E",
											color: "white",
											right: -3,
											top: -2,
										}
										: {}
								}
							>
								<Image
									height={["18px", "24px"]}
									src={src}
									alt={label}
									cursor="pointer"
								/>
							</Flex>
							{!onlyImage && (
								<Text
									size="sm"
									fontSize={["13px", "none"]}
									display={{ base: "initial", lg: "none" }}
								>
									{label}
								</Text>
							)}
						</VStack>
					</Tooltip>
				</Link>
			)}
		</>
	);
};
