import Routes from "../../../resources/Routes";
import Strings from "../../../resources/Strings";
import * as api from "@startapp/laira-user-ssr-api";

interface ITabItem {
	label: string;
	route: {
		href: string;
		as: string;
	};
	src: string;
	haveNotification?: number;
}

export const sellerItems = (currentUser: api.User): ITabItem[] => {
	return [
		{
			label: Strings.components.navbar.buttons.profile,
			route: Routes.profile(),
			src: "/userIcon.svg",
		},
		{
			label: Strings.components.navbar.buttons.myStore,
			src: "/store.svg",
			route: Routes.myStore(currentUser?.nick),
		},
		{
			label: Strings.components.navbar.buttons.chat,
			src: "/chatIcon.svg",
			route: Routes.chats(),
		},
		{
			label: Strings.components.navbar.buttons.cart,
			src: "/shoppingCartIcon.svg",
			route: Routes.shoppingCart(),
			haveNotification: currentUser?.badges.cart,
		},
		{
			label: Strings.components.navbar.buttons.sales,
			src: "/assessment.svg",
			route: Routes.orderHistoryStore(),
		},
	];
};

export const userItems = (currentUser: api.User): ITabItem[] => {
	return [
		{
			label: Strings.components.navbar.buttons.profile,
			route: Routes.profile(),
			src: "/userIcon.svg",
		},
		{
			label: Strings.components.navbar.buttons.chat,
			src: "/chatIcon.svg",
			route: Routes.chats(),
		},
		{
			label: Strings.components.navbar.buttons.cart,
			src: "/shoppingCartIcon.svg",
			route: Routes.shoppingCart(),
			haveNotification: currentUser?.badges.cart,
		},
		{
			label: Strings.components.navbar.buttons.orders,
			src: "/inventory_black.svg",
			route: Routes.orderHistory(),
		},
	];
};

export const notLoggedTopItems = (): ITabItem[] => {
	return [
		{
			label: Strings.components.navbar.buttons.profile,
			route: Routes.profile(),
			src: "/userIcon.svg",
		},
		{
			label: Strings.components.navbar.buttons.cart,
			src: "/shoppingCartIcon.svg",
			route: Routes.shoppingCart(),
		},
	];
};
