import React, { useCallback } from "react";

// MARK: Resources
import Strings from "../../../resources/Strings";
import * as api from "@startapp/laira-user-ssr-api";

// MARK: Hooks
import { useInsaneForm } from "../../../hooks/useInsaneForm";

// MARK: Components
import {
	Box,
	Button,
	HStack,
	Image,
	Input,
	InputGroup,
	InputRightElement,
	Text,
} from "@chakra-ui/react";
import { AutoCompleteProducts } from "components/domain/AutoCompleteProducts";
import { useDebounce } from "hooks/useDebounce";

interface IRequest {
	inputValue: string;
}

interface IProps {
	onSubmit: (currentValue: IRequest["inputValue"]) => void;
	defaultValue?: string | number;
	category?: api.Category;
}

export const SearchInput: React.FC<IProps> = ({ defaultValue, onSubmit, category }) => {
	const [items, setItems ] = React.useState<api.Product[]>([]);
	const [isOpen, setIsOpen ] = React.useState<boolean>(false);
	const handleSubmit = useCallback(
		(data: IRequest) => onSubmit(data.inputValue),
		[],
	);

	const { submit, field } = useInsaneForm({
		initialValues: { inputValue: defaultValue ? defaultValue : "" },
		onSubmit: handleSubmit,
	});

	const debounce = useDebounce();

	const handleChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const inputValue = e.target as HTMLInputElement;
		defaultValue = inputValue.value;
		debounce.clearTimer();
		debounce.setTimer(
			setTimeout(async () => {
				const data = await api.getProductsWithFilter(0, {
					text: inputValue.value,
					storeId: null,
					category: null,
					tags: null,
					order: api.ProductOrder.lowerstPrice,
				});
				setItems(data);
				setIsOpen(true);

				if (!inputValue.value) {
					setIsOpen(false);
				}
			}, 2000),
		);
	};

	return (
		<form onSubmit={submit}>
			<Box position="relative">
			<InputGroup>
				<Input
					borderRadius={0}
					variant="outline"
					onKeyUp={handleChange}
					placeholder={Strings.components.navbar.search}
					width={{ base: "100%", lg: "400px" }}
					{...field("inputValue")}
				/>
				<InputRightElement
					children={
						<Button type="submit" variant="ghost" padding={1}>
							<Image
								src="/searchIcon.svg"
								alt={Strings.general.search}
								height="100%"
								padding={1}
							/>
						</Button>
					}
				/>
			</InputGroup>
			{
				items && (
					<Box
						mt={2}
						position="absolute"
						w="100%"
						bgColor="#ffffff"
						zIndex={1}
					>
						<AutoCompleteProducts
							searching={!!defaultValue}
							data={items}
							isOpen={isOpen}
							onClick={() =>  setIsOpen(false)}
						/>
					</Box>
				)
			}
			</Box>
			<HStack
				position="absolute"
				textAlign="center"
				bottom={-8}
				fontWeight="100"
				d={{ base: "none", lg: "flex" }}
			>
				{defaultValue && (
					<>
						<Text
							fontSize="md"
							color="gray.700"
							letterSpacing="wide"
							isTruncated
						>
							{Strings.general.resultSearch}
						</Text>
						<Text
							fontSize="lg"
							color="gray.500"
							letterSpacing="wide"
							isTruncated
						>
							{`"${defaultValue}"`}
						</Text>
					</>
				)}
				{category && (
					<>
						<Text
							fontSize="md"
							color="gray.700"
							letterSpacing="wide"
							isTruncated
						>
							{Strings.general.resultCategory}
						</Text>
						<Text
							fontSize="lg"
							color="gray.500"
							letterSpacing="wide"
							isTruncated
						>
							{`"${api.translateCategory(category)}"`}
						</Text>
					</>
				)}
			</HStack>
		</form>
	);
};
