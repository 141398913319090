import React, { useMemo } from "react";

import { useUserAuth } from "contexts/UserAuthContext";
import { userItems, sellerItems } from "../../tabItems";
import { Flex } from "@chakra-ui/react";
import { IconWithNotification } from "../../../IconNotification";

export const BottomTabBar = () => {
	const { user } = useUserAuth();
	const tabItems = useMemo(
		() => (user?.store ? sellerItems(user) : userItems(user)),
		[user],
	);

	return (
		<Flex
			display={["flex", "flex", "none"]}
			zIndex={100}
			bottom={0}
			position="fixed"
			bgColor="white"
			justify="space-around"
			width={{ base: "100%", lg: "20%" }}
			paddingY={{ base: 4, lg: 0 }}
			borderTopStyle={{ base: "solid", lg: "none" }}
			borderTopWidth="2px"
		>
			{tabItems.map((item, index) => (
				<IconWithNotification key={`${item}#${index}`} {...item} />
			))}
		</Flex>
	);
};
