import React from "react";
import {
	Button,
	Divider,
	Flex,
	Text,
} from "@chakra-ui/react";

export interface IProps {
	onClick: () => void;
	product: string;
}

export const SelectItem: React.FC<IProps> = (props) => {
	const { product, onClick } = props;

	return (
		<>
			<Flex
				cursor="pointer"
				w="100%"
				px={5}
				mb={2}
				rounded="lg"
				onClick={onClick}
				_hover={{
					bg: "primary.200",
				}}
				justifyContent="space-between"
				alignItems="center"
			>
				<Text color="black">
					{product}
				</Text>
			</Flex>
		</>
	);
};
