import React from "react";

// MARK: Mobx
import { useLocalObservable, observer } from "mobx-react-lite";

// MARK: Stores
import UIStore from "../stores/UIStore";

const uiStoreContext = React.createContext<UIStore | null>(null);

export const UIStoreProvider = observer((props) => {
	const uiStore = useLocalObservable(() => new UIStore());
	return (
		<uiStoreContext.Provider value={uiStore}>
			{props.children}
		</uiStoreContext.Provider>
	);
});

export function useUIStore() {
	const store = React.useContext(uiStoreContext);
	if (!store) {
		throw new Error("Cannot Access Store outside it's context");
	}
	return store;
}
